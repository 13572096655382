import { ServicoHttp } from "@/axios/servico-http";
import { ServicoHttpCallingCircle } from "@/axios/servico-http-calling-circle";
import { ServicoAll } from "@/axios/servico-all";

class ApiService {
  constructor () {
    console.log('CONSTRUCT ApiService')
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);

    this.apiCallingCircleServiceRequestEnter = new ServicoAll(
      "/solicitacao-atendimento/enter",
      this.servicoHttpCallingCircle
    );
    this.apiCallingCircleServiceRequestExit = new ServicoAll(
      "/solicitacao-atendimento/exit",
      this.servicoHttpCallingCircle
    );
    this.apiCleanPatient = new ServicoAll(
      "/solicitacao-atendimento/limpar-paciente",
      this.servicoHttpCallingCircle
    );
    this.sSaveHistoric = new ServicoAll(
      "/api/pep/historico-atendimento/save",
      this.servicoHttp
    );
    this.sCancelCall = new ServicoAll(
      "/api/pep/circle/patient-cancel-call",
      this.servicoHttp
    );

    // PACIENTES
    this.apiCleanPatient = new ServicoAll(
      "/solicitacao-atendimento/limpar-paciente",
      this.servicoHttpCallingCircle
    );

    // AGENDAMENTO
    this.sGetSchedulePatientById = new ServicoAll('/api/pep/schedule/get-id', this.servicoHttp)

    // OUTROS
    this.sServiceHoursSave = new ServicoAll('/api/pep/service-hours/save', this.servicoHttp)
    this.sServiceHoursUpdate = new ServicoAll('/api/pep/service-hours/update', this.servicoHttp)
  }
}

export default ApiService
