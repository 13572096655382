import Firebase from "@/helpers/firebase/index";

class FirebaseService {
  constructor() {
    console.log('CONSTRUCT FirebaseService')
    Firebase.setFirebaseConfig()
    Firebase.setFirestoreConfig()
  }

  // * LISTENERS
  listenerRoomLinkAgoraByPatientId (patientId, callback) {
    Firebase.listenerRoomLinkAgora(patientId, async (payload) => {
      callback(payload)
    });
  }

  listenerRoomSpecificLinkAgora (channelName, callback) {
    Firebase.listenerRoomSpecificLinkAgora(channelName, async (payload) => {
      callback(payload)
    });
  }

  listenerByPatientId (patientId, callback) {
    Firebase.onListenerByPacId(patientId, async (payload) => {
      callback(payload)
    });
  }

  // * ACTS
  async updatePatientSpecificKey (patientId, key, value) {
    await Firebase.updatePatientSpecificKey(patientId, key, value)
  }

  async getLinkAgoraByPatientId (patientId) {
    const room = await Firebase.getLinkAgoraByPatientId(patientId)
    return room
  }

  async checkLockedRoom (patientId) {
    const rooms = await Firebase.getAllLinkAgoraByPatientId(patientId)
    if (!room.bloqueado) {
      return false
    }
    const lockedRoom = rooms.some(room => room.bloqueado === 1)
    return lockedRoom
  }
  async checkUnlockedRoom (patientId) {
    const rooms = await Firebase.getAllLinkAgoraByPatientId(patientId)
    if (!room.bloqueado) {
      return true
    }
    const unlockedRoom = rooms.some(room => room.bloqueado === 0)
    return unlockedRoom
  }

  async updateRoomSpecificKey (channel, key, value) {
    await Firebase.updateRoomSpecificKey(channel, key, value)
  }

  async updateStreamIdPacienteByRoom (channel, streamId) {
    await Firebase.updateStreamIdPacienteByRoom(channel, streamId)
  }
}

export default FirebaseService
