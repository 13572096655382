import moment from "moment";

import ApiService from './ApiService'

class CallingCircleService {
  constructor () {
    this.ApiService = new ApiService()
  }

  async startCallingCircle (objPatient, appVersion, spaId) {
    const momNow = moment();
    const momBirth = moment(objPatient.usu_nascimento, "YYYY-MM-DD");
    const age = momNow.diff(momBirth, "years");
    let sex = 'Não informado'
    if (objPatient.usu_genero) {
      sex = objPatient.usu_genero === "m" ? "Masculino" : "Feminino"
    }
    const payload = {
      isWeb: true,
      pac_genero: sex,
      pac_idade: age,
      systemVersion: appVersion,
      pac_id: objPatient.pac_id,
      pac_nome: objPatient.pac_nome,
      pac_imagem: objPatient.usu_imagem_url,
      usu_cpf: objPatient.usu_cpf,
      spa_id: spaId,
    }
    try {
      const responseApiCallingCircle = await this.ApiService.apiCallingCircleServiceRequestEnter.post(payload)
      return responseApiCallingCircle
    } catch (error) {
      console.error('[CallingCircleService] startCallingCircle ERROR:', error)
      return null
    }
  }

  async handleCancelCall (patientId, qsKey, vcuId) {
    try {
      await this.ApiService.apiCallingCircleServiceRequestExit.post({
        patientId: patientId
      })
      this.ApiService.apiCleanPatient.post({
        patientId: patientId
      });

      // Cria registro de Pronto Atendimento Cancelado no historico
      const paramsHist = {
        hat_id_funcionarios: null,
        hat_id_pacientes: patientId,
        hat_id_clientes: null,
        hat_id_atendimentos: null,
        hat_id_tipo_historico: 5, // Pronto Atendimento Cancelado
        hat_id_mevo_documentos: null,
        hat_id_agendamentos_pacientes: null,
      };
      console.log('paramsHist', paramsHist)
      this.ApiService.sSaveHistoric.post(paramsHist)

      const objCancel = {
        pacId: patientId,
        isMeet: false,
        vcuId: null,
      }
      const ret = await this.ApiService.sCancelCall.post(objCancel);

      // this.ApiService.sServiceHoursSave.post({
      //   isCancelCall: true,
      //   hap_id_pacientes: patientId,
      //   hap_hora_cancelamento: moment().format('YYYY-MM-DD HH:mm:ss')
      // })
    } catch (error) {
      console.error('[CaalingCircleService] handleCancelCall. Error', error)
      throw new Error(error)
    }
  }
}

export default CallingCircleService
