<style lang="scss" src="./Chat.scss" scoped></style>

<template>
  <section class="pep-chat__content">
    <div class="chat-header">
      <div class="ball header">
        <img class="logo" :src="appLogoChat"/>
      </div>
      {{ objFun ? objFun.fun_nome : 'Não Informado' }}
    </div>
    <div class="chat-date"> Hoje {{ dateNow }} </div>
    <div class="msgs" ref="scrollContainer">
      <div class="tag"
        :class="[
          {'my': item.isPatient === 1},
          {'other': item.isPatient === 0},
        ]"
        v-for="(item, i) in listMsgs" :key="`chat-tag-${i}`"
      >
        <div class="ball cheader" v-if="item.isPatient === 0">
          <img class="logo" :src="appLogoChat"  />
        </div>
        <div class="tag_msg" :class="[
          {'my': item.isPatient === 1},
          {'other': item.isPatient === 0},
        ]">
          <div class="msg" :class="[
            {'my': item.isPatient === 1},
            {'other': item.isPatient === 0},
          ]">{{ item.msg }}</div>
            <div class="time" :class="[
              {'my': item.isPatient === 1},
              {'other': item.isPatient === 0},
            ]">
              {{ item.isPatient === 0 ?
                "Atendimento • " :
                  'Você' + " • "}}
              {{ item.time || 'Não Informado' }}
          </div>
        </div>
        <div class="ball user" v-if="item.isPatient === 1">{{acronymPatient}}</div>
      </div>
    </div>
    <div class="actions">
      <input ref="input" class="send-input"
        placeholder="Digite sua mensagem aqui..."
        v-model="chatMsg" @keyup.enter="sendMsg"/>
      <button class="send-btn" @click="sendMsg"><i class="fas fa-paper-plane" /></button>
    </div>
    <div class="show-info-message"><span  v-show="showMessage">Por favor, digite sua mensagem.</span></div>
  </section>
</template>

<script>
import Firebase from "@/helpers/firebase/index";
import { TextInput } from "@/components/inputs";
import MovementLogs from "@/helpers/movement_logs";
import moment from 'moment'

export default {
  name: 'Chat',
  components: {
    TextInput
  },
  props: {
    objFun: {
      type: Object,
      default: null
    }
  },

  data: () => {
    return {
      dateNow: null,
      listMsgs: [],
      chatMsg: null,
      pacId: null,
      funId: null,
      patient: null,
      acronymPatient: null,
      patientName: null,
      showMessage: false
    }
  },

  watch: {
    objFun: {
      // immediate: true,
      deep: true,
      handler (value, oldValue) {
        if (value) {
          this.funId = this.objFun.fun_id
          this.enableChat(this.funId)
        }
      }
    }
  },

  async mounted () {
    this.patient = await this.$store.dispatch("getLocalStoragePatient")
    this.pacId = this.patient.pac_id
    this.dateNow = moment().format('DD/MM/YYYY')

    this.acronymPatient = this.patient.pac_nome.substring(0, 1)
    const firstPatientName = this.patient.pac_nome.split(' ')
    this.patientName = firstPatientName[0]

    // CASO QUEIRA TESTAR O CHAT SEM ENTRAR EM LIGACAO, SO DESCOMENTAR
    // this.funId = 1
    // this.enableChat(this.funId)
    if (this.objFun && this.objFun?.fun_id) {
      this.funId = this.objFun.fun_id
      this.enableChat(this.funId)
    }
  },

  methods: {
    sendMsg () {
      this.$refs.input.focus()

      if (!this.chatMsg || this.chatMsg.length < 1) {
        this.showMessage = true
      }

      if (this.chatMsg.length > 0) {
        // ! Firebase LOG
        const objLog = { ...this.patient, fun_id: this.funId, chatMsg: this.chatMsg }
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        MovementLogs.saveLog(1710, timeAction, objLog)

        Firebase.senChatMsg(this.pacId, this.funId, this.chatMsg)
        this.showMessage = false
      }

      this.chatMsg = null
    },

    async enableChat (funId) {
      await Firebase.setFirestoreConfig()
      Firebase.onListenerChatByPacIdFunId(this.pacId, funId, (snap) => {
        this.listMsgs = snap

        // ! Firebase LOG
        // const objLog = { ...this.patient, fun_id: funId }
        // const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        // MovementLogs.saveLog(1711, timeAction, objLog)

        setTimeout(() => {
          const container = this.$refs.scrollContainer
          container.scrollTop = container.scrollHeight + 1000
        }, 250)
      })
    }
  }
}
</script>
