import AgoraRTC from "agora-rtc-sdk-ng";
import config from "./config.json";
import VirtualBackgroundExtension from 'agora-extension-virtual-background'

const AgoraManager = async (eventsCallback) => {
  let agoraEngine = null;

  // ! Registro do Virtual Background
  const virtualBackgroundExtension = new VirtualBackgroundExtension()
  if (!virtualBackgroundExtension.checkCompatibility()) {
    console.error('Sem suporte para Virtual Background!')
  }
  AgoraRTC.registerExtensions([virtualBackgroundExtension])
  let virtualBackgroundProcessor = null

  // Set mode to "live" for broadcast streaming or "rtc" for video calling
  const setupAgoraEngine = async () => {
    console.warn('[Agora manager]', 'START setupAgoraEngine')
    agoraEngine = new AgoraRTC.createClient({ mode: 'rtc', codec: "vp8" });
  };
  console.warn('[Agora manager]', 'START ALL')
  await setupAgoraEngine();
  const getAgoraEngine = () => {
    return agoraEngine;
  };


  // ! Event Listeners
  console.warn('[Agora manager]', 'Start Listeners', agoraEngine)
  agoraEngine.on("user-left", async (user, mediaType) => {
    console.warn("user-left success");
    eventsCallback("user-left", user, mediaType)
  });
  agoraEngine.on("user-joined", async (user, mediaType) => {
    console.warn("user-joined success");
    eventsCallback("user-joined", user, mediaType)
  });
  agoraEngine.on("stream-added", async (user, mediaType) => {
    console.warn("stream-added success");
    eventsCallback("stream-added", user, mediaType)
  });
  agoraEngine.on("stream-subscribed", async (user, mediaType) => {
    console.warn("stream-added success");
    eventsCallback("stream-added", user, mediaType)
  });
  agoraEngine.on("user-published", async (user, mediaType) => {
    // Subscribe to the remote user when the SDK triggers the "user-published" event.
    await agoraEngine.subscribe(user, mediaType);
    console.warn("subscribe success");
    eventsCallback("user-published", user, mediaType)
  });
  agoraEngine.on("user-unpublished", (user) => {
    console.warn(user.uid + "has left the channel");
    eventsCallback("user-unpublished", user)
  });

  // ! JOIN
  const join = async (appendLocalPlayerContainer, localPlayerContainer, channelParameters, joinParameters) => {
    console.warn('[Agora manager]', 'Start Join', joinParameters)
    await agoraEngine.join(
      joinParameters.appId,
      joinParameters.channelName,
      joinParameters.token,
      `pacCpf_${joinParameters.pacCpf}`
    );

    console.warn('[Agora manager]', 'Start Create Local Video')
    // Create a local audio track from the audio sampled by a microphone.
    channelParameters.localAudioTrack =
      await AgoraRTC.createMicrophoneAudioTrack();
    // Create a local video track from the video captured by a camera.
    channelParameters.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    // Append the local video container to the page body.
    localPlayerContainer.id = `local_video`
    localPlayerContainer.style.width = '100%';
    localPlayerContainer.style.height = '100%';
    appendLocalPlayerContainer.append(localPlayerContainer);
    // Publish the local audio and video tracks in the channel.

    console.warn('PUBLISH', channelParameters)
    console.warn('agoraEngine', agoraEngine)
    await agoraEngine.publish([
      channelParameters.localAudioTrack,
      channelParameters.localVideoTrack,
    ]);
    // Play the local video track.
    channelParameters.localVideoTrack.play(localPlayerContainer);
  };

  // ! LEAVE
  const leave = async (channelParameters) => {
    if (!channelParameters.localAudioTrack || !channelParameters.localVideoTrack) {
      return
    }
    // Destroy the local audio and video tracks.
    channelParameters.localAudioTrack.close();
    channelParameters.localVideoTrack.close();
    // Remove the containers you created for the local video and remote video.
    await agoraEngine.leave();
  };

  // ! BACKGROUND
  async function getVirtualBackgroundProcessorInstance (channelParameters) {
    if (!virtualBackgroundProcessor && channelParameters.localVideoTrack) {
      // Create a VirtualBackgroundProcessor instance
      virtualBackgroundProcessor = virtualBackgroundExtension.createProcessor()

      try {
        // Initialize the extension and pass in the URL of the Wasm file
        await virtualBackgroundProcessor.init()
      } catch (e) {
        console.log('Fail to load WASM resource!')
        return null
      }
      // Inject the extension into the video processing pipeline in the SDK
      channelParameters.localVideoTrack
        .pipe(virtualBackgroundProcessor)
        .pipe(channelParameters.localVideoTrack.processorDestination)
    }
    return virtualBackgroundProcessor
  }

  async function setBackgroundColor (channelParameters) {
    if (channelParameters.localVideoTrack) {
      let vbProcessor = await getVirtualBackgroundProcessorInstance(channelParameters)
      vbProcessor.setOptions({ type: 'color', color: '#F0DFD0' })
      await vbProcessor.enable()
    }
  }

  async function setBackgroundBlurring (channelParameters) {
    if (channelParameters.localVideoTrack) {
      let vbProcessor = await getVirtualBackgroundProcessorInstance(channelParameters)
      vbProcessor.setOptions({ type: 'blur', blurDegree: 2 }) // 1 - low, 2 - medium, 3 - high
      await vbProcessor.enable()
    }
  }

  async function setBackgroundImage (channelParameters) {
    const imgElement = document.createElement('img')
    imgElement.onload = async () => {
      let vbProcessor = await getVirtualBackgroundProcessorInstance(channelParameters)
      vbProcessor.setOptions({ type: 'img', source: imgElement })
      await vbProcessor.enable()
    }
    imgElement.src = './background.jpg'
  }

  async function disableBackground (channelParameters) {
    let vbProcessor = await getVirtualBackgroundProcessorInstance(channelParameters)
    vbProcessor.disable()
  }

  // Return the agoraEngine and the available functions
  return {
    getAgoraEngine,
    config,
    join,
    leave,
    setBackgroundColor,
    setBackgroundBlurring,
    setBackgroundImage,
    disableBackground
  };
};

export default AgoraManager;
