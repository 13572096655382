import AgoraGetStarted from '../agora_manager/agora_manager_get_started.js'
import Firebase from "@/helpers/firebase/index"

class AgoraService {
  constructor () {
    console.log('CONSTRUCT AgoraService')
    this.agoraAppID = '61cf3781778b4a15a3460bd5ab7814bb'
    this.AgoraGetStarted = null
    this.remotePlayerContainer = null
    this.localPlayerContainer = null

    this.videoOn = true
    this.audioOn = true
    this.blurOn = true

    this.channelParameters = {
      localAudioTrack: null,
      localVideoTrack: null,
      remoteAudioTrack: null,
      remoteVideoTrack: null,
      remoteUid: null
    }

    this.objRoomAgora = null

    this.initialize()
  }

  async initialize () {
    this.remotePlayerContainer = document.createElement('div')
    this.localPlayerContainer = document.createElement('div')
  }

  async enterRoom (payload, objPatient) {
    this.objRoomAgora = {
      room: payload.canal,
      token: payload.token,
      uid: payload.canal
    }

    const joinParameters = {
      appId: this.agoraAppID,
      channelName: payload.canal,
      token: payload.token,
      pacCpf: objPatient.usu_cpf
    }
    const localStreamDiv = document.getElementById('local_stream')

    this.remotePlayerContainer = document.createElement("div");
    this.localPlayerContainer = document.createElement("div");
    this.AgoraGetStarted = await AgoraGetStarted(this.handleEvents.bind(this))

    await this.AgoraGetStarted.join(localStreamDiv, this.localPlayerContainer, this.channelParameters, joinParameters);
    Firebase.updateStreamIdPacienteByRoom(payload.canal, `pacCpf_${joinParameters.pacCpf}`)
  }

  handleEvents = (eventName, ...args) => {
    switch (eventName) {
      case "user-published":
        if (args[1] == "video") {
          const remoteUid = args[0].uid.toString();
          if (!this.objRoomAgora.room.includes(remoteUid)) {
            this.$toasted.info(`Uma pessoa não autorizada está tentando entrar na sala.`, {
              duration: 5000
            })

            const numberPattern = /\d+/;
            const result = remoteUid.match(numberPattern);
            const funId = result[0];
            Firebase.updateProfessionalSpecificKey(funId, 'comando', 'sairDaSala')
            return
          }
          this.channelParameters.remoteVideoTrack = args[0].videoTrack;
          this.channelParameters.remoteAudioTrack = args[0].audioTrack;
          this.channelParameters.remoteUid = remoteUid;
          // ! coloca o video remoto (profissional)
          console.warn('USER PUBLISHED', args[0])
          this.remotePlayerContainer.id = remoteUid;
          this.remotePlayerContainer.style.minWidth = '60vw'
          // Append the remote container to the page body.
          document.getElementById('expand_stream').appendChild(this.remotePlayerContainer)
          // Play the remote video track.
          this.channelParameters.remoteVideoTrack.play(this.remotePlayerContainer);
          this.profissionalEntered = true
        }
        // Subscribe and play the remote audio track If the remote user publishes the audio track only.
        if (args[1] == "audio") {
          // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
          this.channelParameters.remoteAudioTrack = args[0].audioTrack;
          // Play the remote audio track. No need to pass any DOM element.
          this.channelParameters.remoteAudioTrack.play();
        }
        break
      case "user-unpublished":
        console.warn('user-unpublished')
        // Firebase.nullableStreamIdProfissionalByRoom(this.objRoomAgora.room)
        break
    }
  }

  async leaveRoom () {
    console.warn('LEAVE ROOM', this.channelParameters.localVideoTrack)
    if (!this.channelParameters.localVideoTrack) {
      console.warn('Sem localVideoTrack')
      return
    }
    await this.AgoraGetStarted.leave(this.channelParameters);
    this._removeView(`local_video`)

    if (this.remotePlayerContainer?.id) {
      this._removeView(this.remotePlayerContainer.id)
    }
  }

  videoOnOff (callback) {
    if (this.videoOn) {
      this.videoOn = false
      this.channelParameters.localVideoTrack.setMuted(true)
    } else {
      this.videoOn = true
      this.channelParameters.localVideoTrack.setMuted(false)
    }
    const obj = {
      status: this.videoOn
    }
    callback(obj)
  }

  audioOnOff (callback) {
    if (this.audioOn) {
      this.audioOn = false
      this.channelParameters.localAudioTrack.setMuted(true)
    } else {
      this.audioOn = true
      this.channelParameters.localAudioTrack.setMuted(false)
    }
    const obj = {
      status: this.audioOn
    }
    callback(obj)
  }

  async blurOnOff (callback) {
    if (this.blurOn) {
      this.blurOn = false
      await this.AgoraGetStarted.disableBackground(this.channelParameters)
    } else {
      this.blurOn = true
      await this.AgoraGetStarted.setBackgroundColor(this.channelParameters)
    }
    const obj = {
      status: this.blurOn
    }
    callback(obj)
  }

  // * PRIVATE
  _removeView (id) {
    console.warn("Removing " + id + "Div");
    const div = document.getElementById(id);
    if (div) {
      div.remove();
    }
  }
}

export default AgoraService
