<style src="./PatientLinks.scss" lang="scss" scoped></style>
<template>
  <section class="patient-schedule">
    <Loading v-if="isLoading" />
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Minhas Consultas Agendadas</span>
    </section>

    <section class="content">
      <div class="table-container">
        <table class="table">
          <thead>
            <tr>
              <th>Link</th>
              <th>Status do Atendimento</th>
              <th>Especialidade</th>
              <th>Profissional</th>
              <th>Data</th>
              <th>Hora</th>
              <th>Usou giftcard</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="th"
              v-for="(item, idx) of listLinks"
              :key="`hist-${idx}`"
              :class="{ gray: idx % 2 === 1 }"
            >
              <td class="ti_icon">
                <button
                  title="Abrir link"
                  class="schedule-action"
                  v-if="!item.isCanceled && !item.expired"
                  @click="openLink(item)"
                >
                  <i v-if="item.agp_data" class="fas fa-link" />
                </button>
              </td>
              <td class="ti" v-if="item.isCanceled">Cancelado</td>
              <td class="ti" v-else-if="item.expired">Agendamento expirado</td>
              <td class="ti" v-else>
                {{ item.agp_data ? item.vcu_label_status : `Não agendado` }}
              </td>
              <td class="ti">
                {{ item.esp_nome || "SEM OCUPAÇÃO" }}
              </td>

              <td class="ti">
                {{ item.fun_nome }}
              </td>
              <td class="ti">
                {{ item.agp_data ? `${item.agp_data}` : `Não agendado` }}
              </td>
              <td class="ti">
                {{
                  item.agp_horario_inicio
                    ? `${item.agp_horario_inicio}`
                    : `Não agendado`
                }}
              </td>
              <td class="ti">
                {{item.agp_id_giftcard_codigos_quantidades ? `Sim` : `Não` }}
              </td>

              <td class="ti_icon">
                <button
                  v-if="!item.isCanceled && !item.expired"
                  title="Cancelar Agendamento"
                  class="schedule-action"
                  @click="onClickOpenModalCancel(item)"
                >
                  <!-- @click="handleCancel(item)" -->
                  <i class="fas fa-trash" />
                </button>

                <button
                  v-if="!item.isCanceled && !item.expired"
                  class="schedule-action"
                  title="Copiar link"
                  @click="copyLink(item)"
                >
                  <i class="fas fa-copy" />
                </button>

                <button
                  v-if="!item.isCanceled && !item.expired"
                  class="schedule-action"
                  title="Reenviar Link de Acesso"
                  @click="handleSendVideoUrl(item)"
                >
                  <i class="fas fa-paper-plane" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <!--  -->
    <section class="footer">
      <PlButton label="" primary arrowLeft @click="onClickBack" />
    </section>
    <!--  -->
    <!-- *** *** *** -->
    <!-- MODAL CANCELAR AGENDAMENTO -->
    <section class="modal-cancel-all" v-if="showModalCancel">
      <section class="modal-cancel-content">
        <div class="mc-header">
          <span>Cancelar Agendamento</span>
          <button @click="onClickCloseModalCancel">X</button>
        </div>
        <div class="mc-content">
          <div class="mc-title">Deseja cancelar esse agendamento?</div>
          <div class="mc-info">
            <div class="">
              Data:
              <strong>{{
                cancelItem ? cancelItem.agp_data : "Não Informado"
              }}</strong>
            </div>
            <div class="">
              Hora:
              <strong>{{
                cancelItem ? cancelItem.agp_horario_inicio : "Não Informado"
              }}</strong>
            </div>
            <div class="">
              Profissional:
              <strong>{{
                cancelItem ? cancelItem.fun_nome : "Não Informado"
              }}</strong>
            </div>
            <div class="">
              Usou giftcard:
              <strong>{{
                cancelItem.agp_id_giftcard_codigos_quantidades ? "Sim" : "Não"
              }}</strong>
            </div>
          </div>

          <div
            class="mc-title"
            style="text-align: center"
            v-if="cancelItem.agp_id_giftcard_codigos_quantidades"
          >
            Atenção: Se o cancelamento de sua consulta ocorrer com menos de 48
            horas da data marcada, sua cota não será restituída.
          </div>
          <div class="mc-input">
            <TextInput
              label="Motivo de Cancelamento"
              required
              v-model="reasonCancel"
            />
          </div>
        </div>
        <div class="mc-footer">
          <PlButton label="Cancelar" red trash hollow @click="handleCancel" />
        </div>
      </section>
    </section>
    <!-- *** *** *** -->
  </section>
</template>

<script>
import moment from "moment";
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import MovementLogs from "@/helpers/movement_logs";

import PatientHeader from "../components/header/PatientHeader";
import Loading from "../components/loading/Loading";

import { TextInput } from "@/components/inputs";
import PlButton from "@/components/button/Button";
import SelectInput from "@/components/select-input/SelectInput.vue";
import Button from "@/components/button/Button.vue";
import IconArrow from "@/assets/icons/IconArrow";

export default {
  name: "PatientFiles",
  components: {
    SelectInput,
    PlButton,
    TextInput,
    PatientHeader,
    Button,
    IconArrow,
    Loading,
  },

  data() {
    return {
      isLoading: false,
      servicoHttp: null,
      runningActCancel: false,
      patient: null,
      listLinksOri: [],
      listLinks: [],
      filter: {
        date: null,
        prof: null,
      },
      cancelItem: null,
      showModalCancel: false,
      reasonCancel: null,
    };
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPacLinks = new ServicoAll(
      "/api/pep/schedule/links",
      this.servicoHttp
    );
    this.sGetSchedulePatientById = new ServicoAll('/api/pep/schedule/get-id', this.servicoHttp)
    this.sPepPacCancel = new ServicoAll(
      "/api/pep/gift-card/cancel-gift-card-quota-specialty",
      this.servicoHttp
    );

    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.getHistoric();

    // ! Firebase LOG
    const objLog = { ...this.patient };
    const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
    MovementLogs.saveLog(1400, timeAction, objLog);
  },

  methods: {
    onClickCloseModalCancel() {
      this.showModalCancel = false;
      this.reasonCancel = null;
      this.cancelItem = null;
    },
    onClickOpenModalCancel(item) {
      if (item.expired) {
        this.$toast.info("O agendamento foi expirado.");
        return;
      }

      this.showModalCancel = true;
      this.reasonCancel = null;
      this.cancelItem = item;
    },
    // *** *** ***
    onClickBack() {
      this.$router.push({ name: "pacienteHome" });
    },

    async getHistoric() {
      this.isLoading = true;
      const { data: response } = await this.sPepPacLinks.post({
        pacId: this.patient.pac_id,
      });
      this.listLinksOri = [];

      if (!response.status) {
        this.isLoading = false;
        return this.$toast.error(
          "Falha ao obter histórico, tente novamente mais tarde!"
        );
      }

      this.listLinks = response.data.map((item) => ({
        ...item,
        isCanceled: item.agp_cancelado === 1,
        expired: moment(
          `${item.agp_data} ${item.agp_horario_inicio}`,
          "DD/MM/YYYY HH:mm"
        ).isBefore(moment(), "day"),
      }));
      // ordernacao
      this.listLinks.sort((a, b) => {
        const dateA = moment(
          `${a.agp_data} ${a.agp_horario_inicio}`,
          "DD/MM/YYYY HH:mm"
        );
        const dateB = moment(
          `${b.agp_data} ${b.agp_horario_inicio}`,
          "DD/MM/YYYY HH:mm"
        );
        return dateB.diff(dateA);
      });
      // separar cada item em um bloco
      const expiredItems = this.listLinks.filter(
        (item) => item.expired && !item.isCanceled
      );
      const canceledItems = this.listLinks.filter((item) => item.isCanceled);
      const otherItems = this.listLinks.filter(
        (item) => !item.expired && !item.isCanceled
      );
      otherItems.sort((a, b) => {
        const dateA = moment(
          `${a.agp_data} ${a.agp_horario_inicio}`,
          "DD/MM/YYYY HH:mm"
        );
        const dateB = moment(
          `${b.agp_data} ${b.agp_horario_inicio}`,
          "DD/MM/YYYY HH:mm"
        );
        return dateA.diff(dateB);
      });

      this.listLinks = [...otherItems, ...expiredItems, ...canceledItems];


      this.isLoading = false;
    },

    async openLink (item) {
      this.isLoading = true;
      if (item.expired) {
        this.$toast.info("O agendamento foi expirado.");
        return;
      }
      const hash = item.vcu_hash;

      // ! Firebase LOG
      const objLog = { ...this.patient, ...item };
      const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
      MovementLogs.saveLog(1401, timeAction, objLog);

      const agpId = item.agp_id
      const resAgp = await this.sGetSchedulePatientById.get({
        agpId: agpId
      })
      if (resAgp.status) {
        const objAgp = resAgp.data
        const linkAgoraFirst = `AG-funId_${objAgp.agp_id_funcionarios}-pacCpf_${this.patient.usu_cpf}`

        let roomNameAddon = ``
        if (objAgp.day && objAgp.month && objAgp.year && objAgp.hour && objAgp.minute) {
          roomNameAddon = `${objAgp.day}${objAgp.month}${objAgp.year}_${objAgp.hour}${objAgp.minute}`
        } else if (objAgp.agp_data) {
          const momentDate = moment(`${objAgp.agp_data}`, 'DD/MM/YYYY')
          const month = momentDate.month() + 1 < 10 ? `0${momentDate.month() + 1}` : momentDate.month() + 1
          const momentDateFormat = `${momentDate.date()}${month}${momentDate.year()}`
          const momentHourFormat = moment(`${objAgp.agp_horario_inicio}`, 'HH:mm').format('HHmm')
          roomNameAddon = `${momentDateFormat}_${momentHourFormat}`
        } else {
          this.$toast.error('Indevidas informações da Sala.')
        }
        const linkAgoraAll = `${linkAgoraFirst}_${roomNameAddon}`
        this.isLoading = false
        window.location.href = `${process.env.VUE_APP_URL_PORTAL_PACIENTE}?linkAgenda=${linkAgoraAll}&agendamento=${objAgp.agp_id}`
      } else {
        this.$toast.info(
          "Não foi possível recuperar as informações do seu agendamento."
        );
        this.isLoading = false
      }

    },

    async copyLink(item) {
      if (item.expired) {
        this.$toast.info("O agendamento foi expirado.");
        return;
      }
      this.isLoading = true;
      const hash = item.vcu_hash;
      const urlVideochamada = `${process.env.VUE_APP_URL_PORTAL_PACIENTE}?agendamento=${item.agp_id}&key=${hash}`;
      const inputElement = document.createElement("input");
      inputElement.value = urlVideochamada;
      document.body.appendChild(inputElement);
      inputElement.select();
      document.execCommand("copy");
      document.body.removeChild(inputElement);
      this.isLoading = false;
      this.$toast.success("Link copiado para área de transferência!");
    },

    async handleCancel() {
      if (this.runningActCancel) {
        this.$toast.info(
          "O cancelamento está em andamento. Por favor, aguarde."
        );
        return;
      }
      this.runningActCancel = true;

      this.isLoading = true;
      if (!this.reasonCancel) {
        this.isLoading = false;
        this.runningActCancel = false;
        this.$toast.warning("Motivo de Cancelamento é obrigatório");
        return;
      }

      if (this.reasonCancel.length < 5) {
        this.isLoading = false;
        this.runningActCancel = false;
        this.$toast.warning(
          "Motivo de Cancelamento deve conter no mínimo 5 letras"
        );
        return;
      }

      const item = this.cancelItem;
      const currentDateTime = moment();
      const formDateTime = moment(
        `${item.agp_data} ${item.agp_horario_inicio}`,
        "DD/MM/YYYY HH:mm"
      );
      const minutesDifference = formDateTime.diff(currentDateTime, "minutes");

      if (formDateTime.isBefore(currentDateTime)) {
        this.runningActCancel = false;
        this.isLoading = false;
        return this.$toast.info(
          "Não é possível cancelar agendamento retroativo"
        );
      }

      if (minutesDifference > 0 && minutesDifference < 15) {
        this.runningActCancel = false;
        this.isLoading = false;
        return this.$toast.info(
          "O horário ultrapassou o tempo mínimo de antecedência de 15 minutos"
        );
      }

      try {
        const objCancel = {
          fun_id: item.fun_id,
          agp_id: item.agp_id,
          agp_cancelado: 1,
          agp_motivo_cancelamento: this.reasonCancel,
        };
        await this.servicoHttp.post({
          url: "api/pep/schedule/cancel",
          body: objCancel,
        });

        if (item.agp_id_giftcard_codigos_quantidades) {
          const parsedDate = moment(
            `${item.agp_data} ${item.agp_horario_inicio}:00`,
            "DD/MM/YYYY HH:mm:ss"
          );
          const twoDaysAfter = moment().add(48, "hours");
          if (twoDaysAfter.isBefore(parsedDate)) {
            this.sPepPacCancel.post({
              id: item.agp_id_giftcard_codigos_quantidades,
            });
          }
        }

        this.getHistoric();
        this.onClickCloseModalCancel();

        // ! Firebase LOG
        const objLog = { ...this.patient, ...objCancel };
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        MovementLogs.saveLog(1402, timeAction, objLog);

        this.runningActCancel = false;
        this.isLoading = false;
        return this.$toast.success("Agendamento cancelado com sucesso!");
      } catch (error) {
        this.isLoading = false;
        this.runningActCancel = false;
        console.error(error);
        return this.$toast.error(
          "Só é permitido cancelar agendamento com 30 minutos de antecedência!"
        );
      }
    },

    async handleSendVideoUrl(item) {
      try {
        if (item.expired) {
          this.$toast.info("O agendamento foi expirado.");
          return;
        }
        const id = item.agp_id;
        this.isLoading = true;
        await this.servicoHttp.post({
          url: "api/pep/schedule/enviar-video-url",
          body: { id },
        });

        // ! Firebase LOG
        const objLog = { ...this.patient, id: id };
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        MovementLogs.saveLog(1403, timeAction, objLog);

        this.isLoading = false;
        this.$toast.success(
          "Seu link de acesso logo será enviado no seu celular/e-mail!"
        );
      } catch (error) {
        this.isLoading = false;
        console.error(error);
        return this.$toast.error(
          "Falha ao reenviar link de acesso, tente novamente mais tarde!"
        );
      }
    },
  },
};
</script>
