<style src="./ForgotPsw.scss" lang="scss" scoped></style>
<template>
  <section class="home color-overlay">
    <!-- FORM -->
    <section class="back-form-box" >
      <section class="form-box">
        <img class="logo" :src="appLogo" />
        <div class="title">Esqueci a Senha</div>
        <!-- procurar paciente -->
        <section class="cpf" v-if="!pacId">
          <div class="cpf">
            <InputText isRequired label="CPF"
              mask="###.###.###-##" v-model="formNext.cpf"
            />
          </div>
          <div class="cpf">
            <InputText label="Primeiro Nome da Mãe" v-model="formNext.mother" />
          </div>
          <div class="cpf">
            <InputText label="Data de Nascimento" placeholder="DD/MM/YYYYY"
              mask="##/##/####" v-model="formNext.birth"
            />
          </div>
          <div class="cpf">
            <InputText label="E-mail" v-model="formNext.email" />
          </div>
          <div class="cpf">
            <InputText label="Telefone" mask="(##)#####-####" v-model="formNext.phone" />
          </div>
        </section>
        <!-- paciente encontrado -->
        <section class="cpf" v-if="pacId">
          <div class="cpf">
            <InputText isRequired type="password" label="Nova Senha" v-model="formPsw.newPsw" />
          </div>
          <div class="cpf">
            <InputText isRequired type="password" label="Confirmar Nova Senha" v-model="formPsw.confirmNewPsw" />
          </div>
        </section>
        <!-- acoes -->
        <div class="act">
          <div class="msg">{{ msgError }}</div>
          <div v-if="!pacId" class="btn" @click="onActNext">Avançar</div>
          <div v-if="pacId" class="btn" @click="onActChange">Trocar Senha</div>
          <div class="back" @click="onActBack">Voltar</div>
        </div>
      </section>
    </section>
    <!-- *** -->
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";

import InputText from "@/components/input/text/InputText";

export default {
  name: "PortalPatientLogin",
  components: {
    InputText,
  },

  data() {
    return {
      formNext: {
        cpf: null,
        // cpf: '13407387750',
        mother: null,
        // mother: 'nome',
        birth: null,
        // birth: '11/06/1990',
        email: null,
        phone: null
      },
      formPsw: {
        newPsw: null,
        confirmNewPsw: null
      },
      pacId: null,
      usuId: null,
      usuCpf: null,
      msgError: "",
    };
  },

  computed: {
    appLogo () {
      return process.env.VUE_APP_IMG_LOGO
    }
  },

  mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPacGet = new ServicoAll("/api/pep/paciente/forgot/check",this.servicoHttp);
    this.sPepPacUp = new ServicoAll("/api/pep/paciente/forgot/update",this.servicoHttp);
  },

  methods: {
    async onActNext() {
      if (!this.formNext.cpf) {
        this.msgError = "CPF não informado";
        return;
      }
      const ret = await this.sPepPacGet.post({ ...this.formNext })
      const data = ret.data
      if (data.status === false) {
        this.msgError = data.msg
      } else {
        this.msgError = null
        this.pacId = data.pacId
        this.usuId = data.usuId
        this.usuCpf = data.usuCpf
      }
    },

    async onActChange () {
      if (!this.formPsw.newPsw) {
        this.msgError = "Nova Senha não informado";
        return
      }
      if (this.formPsw.newPsw.length < 7) {
        this.msgError = "Nova Senha deve ter no mínimo 8 caracteres";
        return
      }
      const regexSpecial = /[^a-zA-Z0-9]/;
      const retRegexSpecial = regexSpecial.test(this.formPsw.newPsw)
      if (!retRegexSpecial) {
        this.msgError = "Nova Senha deve ter pelo menos 1 caracter especial";
        return
      }
      const regexUpper = /[A-Z]/;
      const retRegexUpper = regexUpper.test(this.formPsw.newPsw)
      if (!retRegexUpper) {
        this.msgError = "Nova Senha deve ter pelo menos 1 letra maiúscula";
        return
      }
      const regexLower = /[a-z]/;
      const retRegexLower = regexLower.test(this.formPsw.newPsw)
      if (!retRegexLower) {
        this.msgError = "Nova Senha deve ter pelo menos 1 letra minúscula";
        return
      }
      const regexNumber = /\d/;
      const retRegexNumber = regexNumber.test(this.formPsw.newPsw)
      if (!retRegexNumber) {
        this.msgError = "Nova Senha deve ter pelo menos 1 número";
        return
      }

      if (!this.formPsw.confirmNewPsw) {
        this.msgError = "Confirmar Nova Senha não informado";
        return
      }
      if (this.formPsw.newPsw !== this.formPsw.confirmNewPsw) {
        this.msgError = "Senhas distintas";
        return
      }
      this.msgError = null;
      await this.sPepPacUp.post({
        pacId: this.pacId,
        usuId: this.usuId,
        usuCpf: this.usuCpf,
        ...this.formPsw
      })
      this.onActBack()
    },

    onActBack () {
      this.$router.push({ name: "pacienteLogin" });
    }
  },
};
</script>
