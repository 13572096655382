<style src="./PatientVideo.scss" lang="scss" scoped></style>
<template>
  <section class="patient-video">
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->
    <section class="content">
      <!-- PRE TELA DE VIDEO -->
      <section class="video-screen" v-show="!showVideo">
        <div class="__title">
          <span v-if="!qsLinkAgenda || !qsAgendamentoPacienteId">Olá, estamos localizando um profissional para realizar seu atendimento.</span>
          <span v-else>Aguarde o profissional entrar na sala</span>
        </div>
        <div class="__columns">
          <div class="__columns-left">
            <span class="subtitle">{{ labelCC }} {{ sublabelCC }}</span>
            <span class="subtitle margin">Por favor, aguarde!</span>
            <div class="lds-ellipsis" v-if="!profissionalEntered">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="col-acts">
              <PlButton class="mt" label="Cancelar ligação" red cross @click="actShowModalCancelCall" />
            </div>
          </div>
          <div class="__columns-right">
            <SvgImage />
          </div>
        </div>
      </section>
      <!-- TELA DE VIDEO -->
      <section class="area-video" v-show="showVideo">
        <!-- VIDEO -->
        <section class="two-columns">
          <!-- VIDEO AREA -->
          <section class="video-area">
            <div class="video-screen">
              <div class="expand_stream" id="expand_stream"></div>
            </div>

            <div class="video-local">
              <div class="local_stream" id="local_stream"></div>
            </div>
          </section>
          <!-- *** *** *** -->
          <!-- CHAT -->
          <section class="sec-chat">
            <Chat :objFun="objFun" v-if="profissionalEntered"/>
          </section>
        </section>
        <!--  -->
        <div class="video__actions">
          <!-- <div class="btn-blur" itle="Desfoque" @click="onClickBlurOnOff">
            <div class="btn-blur_enable" v-if="blurOn === true"></div>
          </div> -->
          <div class="va_btn" :class="{ red: videoOn === false }" title="Vídeo" @click="onClickVideoOnOff">
            <i class="fas fa-video" v-if="videoOn === true" />
            <i class="fas fa-video-slash" v-if="videoOn === false" />
          </div>
          <div class="va_btn" :class="{ red: audioOn === false }" title="Microfone" @click="onClickAudioOnOff">
            <i class="fas fa-microphone" v-if="audioOn === true" />
            <i class="fas fa-microphone-slash" v-if="audioOn === false" />
          </div>
        </div>
        <PlButton class="mt" label="Cancelar ligação" red cross @click="actShowModalCancelCall" />
        <!-- *** *** *** -->
      </section>
      <!-- *** *** *** -->
    </section>
    <!-- *** *** *** -->
    <!-- MODAL ALERT -->
    <section class="alert-all" v-if="showAlert">
      <section class="alert-content">
        <div class="l-header">Informação</div>
        <div class="l-text">{{ alertTxt }}</div>
        <div class="l-footer">Em breve, esta informação fechará</div>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL API TIMEOUT -->
    <section class="gateway-timeout__modal" v-if="showGatewayTimeoutMessage">
      <section class="gateway-timeout__modal-content">
        <header class="gateway-timeout__modal-header">Conexão Instável</header>
        <section class="gateway-timeout__modal-body">
          <p>Sua conexão apresenta instabilidade.</p>
          <p>Sugerimos tentar novamente com uma conexão mais estável</p>
        </section>
        <footer class="gateway-timeout__modal-footer">
          <PlButton class="mt" label="Cancelar" red @click="actShowModalCancelCall" />
          <PlButton class="mt" label="Tentar Novamente" blue @click="startCallingCircle" />
        </footer>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL DELAY -->
    <section class="gateway-timeout__modal" v-if="showServiceDelayAlert">
      <section class="gateway-timeout__modal-content">
        <header class="gateway-timeout__modal-header">
          Seu atendimento está próximo
        </header>
        <section class="gateway-timeout__modal-body">
          <p>
            Estamos com um grande número de consultas, seu atendimento ocorrerá
            em alguns minutos.
          </p>
          <p>Agradecemos sua compreensão.</p>
        </section>
        <footer class="gateway-timeout__modal-footer">
          <PlButton class="mt" label="Cancelar" red @click="actShowModalCancelCall" />
          <PlButton class="mt" label="Continuar" blue @click="onClickContinueDelayAlert" />
        </footer>
      </section>
    </section>
    <!-- *** *** *** -->
    <!-- MODAL CANCEL CALL -->
    <section class="gateway-timeout__modal" v-if="showModalCancelCall">
      <section class="gateway-timeout__modal-content">
        <header class="gateway-timeout__modal-header">Cancelar Ligação</header>
        <section class="gateway-timeout__modal-body">
          <p>Tem certeza de que deseja cancelar a ligação?</p>
        </section>
        <footer class="gateway-timeout__modal-footer">
          <PlButton class="mt" label="Não" gray @click="showModalCancelCall = false" />
          <PlButton class="mt" label="SIM" red @click="actCancelCall" />
        </footer>
      </section>
    </section>
    <!-- *** *** *** -->
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";

import moment from "moment";
import axios from "axios";
import MovementLogs from "@/helpers/movement_logs";

import PatientHeader from "../components/header/PatientHeader";
import Chat from "../components/chat/Chat";

import PlButton from "@/components/button/Button";
import SvgImage from "./svgImage"

import ApiService from './services/ApiService'
import FirebaseService from './services/FirebaseService'
import AgoraService from './services/AgoraService'
import CallingCircleService from './services/CallingCircleService'

export default {
  name: "PortalPatientVideo",
  components: {
    PlButton,
    PatientHeader,
    Chat,
    SvgImage
  },

  data() {
    return {
      // * CLASS
      ApiService: null,
      FirebaseService: null,
      AgoraService: null,
      CallingCircleService: null,
      // * FIRESTORE
      objListenerRoom: null,
      // * INFO PACITENTE
      patient: null,
      pacId: null,
      pacCpf: null,
      // * QueryString
      qsSpaIdVideo: null,
      qsLinkAgenda: null,
      qsAgendamentoPacienteId: null,
      // * CTRL tela de video
      profissionalEntered: false,
      labelCC: null,
      sublabelCC: null,
      objAgora: null,
      // * CTRL de video
      videoOn: true,
      audioOn: true,
      blurOn: false,
      // * ALERTAS
      showAlert: false,
      showGatewayTimeoutMessage: false,
      showServiceDelayAlert: false,
      // * TRIGGERs
      actRunCLickCancelCall: false,
      enteringRoom: false,
      cmdReloadPage: false,
      runningContinueCall: false,
      attendanceData: {},
      qsRefresh: false,
      sendAsksEnterRoom: false,
      showVideo: false,
      wasInRequestQueue: false,
      showModalCancelCall: false,
      // * AGORA SDK
      objFun: null
    };
  },
  // *** *** ***
  // *** *** ***
  async beforeDestroy () {
    await this.$store.dispatch("resetPatientToVideoUrl");
  },

  beforeMount () {
    this.FirebaseService = new FirebaseService()
    this.ApiService = new ApiService()
    this.CallingCircleService = new CallingCircleService()
    this.AgoraService = new AgoraService()
  },

  async mounted () {
    this.servicoHttp = new ServicoHttp(this);
    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.pacId = this.patient.pac_id;
    this.pacCpf = this.patient.usu_cpf;

    // * QUERY STRING
    this.qsSpaIdVideo = this.$route.query.video || null;
    this.qsLinkAgenda = this.$route.query.linkAgenda || null;
    this.qsAgendamentoPacienteId = this.$route.query.agendamento || null;
    // ! Firebase LOG
    const objLog = { ...this.patient };
    const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
    MovementLogs.saveLog(1700, timeAction, objLog);

    // this.ApiService.sServiceHoursSave.post({
    //   hap_id_pacientes: this.pacId,
    //   hap_hora_solicitacao: moment().format('YYYY-MM-DD HH:mm:ss')
    // })

    // ACOES
    const momBegin = moment(new Date());
    const begin = momBegin.format("YYYY-MM-DD HH:mm:ss");
    await this.FirebaseService.updatePatientSpecificKey(this.pacId, "comecou_ligacao", begin);

    this.listenerPatientCommand();
    await this.getPermissions();

    // validar se há sala criada
    const existRoom = await this.FirebaseService.getLinkAgoraByPatientId(this.pacId)
    if (existRoom) {
      let goListener = false
      if (!existRoom.bloqueado) {
        goListener = true
      } else if (existRoom.bloqueado === 0) {
        goListener = true
      }

      if (goListener) {
        this.objListenerRoom = existRoom
        this.enteringRoom = true
        this.objFun = {
          fun_id: existRoom.funId,
          fun_nome: existRoom.funNome,
        }
        this.listenerRoomSpecificLinkAgora(existRoom.canal)
        return
      }
    }

    // chamada do listener
    if (!this.qsLinkAgenda && !this.qsAgendamentoPacienteId) { // ! se for pronto atendimento
      this.startCallingCircle();
      this.handleListenerRoom();
    } else if (this.qsLinkAgenda || this.qsAgendamentoPacienteId) { // ! se for agendamento
      this.handleListenerRoom();
    } else { // ! se der algo errado
      console.error('[PatientVideo]', 'ERROR', 'Não será possível entrar em uma sala')
      this.$toast.error('Não será possível entrar em uma sala', {
        duration: 5000,
      });
    }
  },

  methods: {
    // * Calling Circle
    async startCallingCircle () {
      console.info('startCallingCircle')
      const objPatient = this.patient
      const appVersion = this.appVersion
      const spaId = this.qsSpaIdVideo
      const resCCService = await this.CallingCircleService.startCallingCircle(objPatient, appVersion, spaId)
      if (!resCCService) {
        this.showGatewayTimeoutMessage = true
      }
    },

    actShowModalCancelCall () {
      this.showModalCancelCall = true
    },

    async actCancelCall (isServiceFinished = false) {
      this.showGatewayTimeoutMessage = false
      try {
        if (this.actRunCLickCancelCall) {
          return;
        }
        this.actRunCLickCancelCall = true;

        if (isServiceFinished) {
          this.showHideAlertNoTime(true, 'Seu atendimento foi finalizado.')
        } else {
          this.showHideAlertNoTime(true, 'Sua chamada está sendo cancelada. Por favor, aguarde um momento.')
        }

        await this.actLeaveRoom();

        const patientId = this.patient.pac_id
        await this.CallingCircleService.handleCancelCall(patientId)

        // ! Firebase LOG
        const objLog = { ...this.patient }
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        MovementLogs.saveLog(1701, timeAction, objLog)

        if (this.objListenerRoom) {
          const channel = this.objListenerRoom.canal
          let key = 'autorizacaoEntrar'
          let value = null
          await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
          // caso pleni/pleni#1464
          // this.FirebaseService.updateStreamIdPacienteByRoom(channel, value) //
          key = `bloqueado`
          value = 1
          await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
        }

        this.showHideAlertNoTime(false, null)
        await this.gotoNps()
      } catch (error) {
        console.error('[PatientVIdeo] actShowModalCancelCall. Error', error)
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 504) {
            this.showHideAlertNoTime(false, null)
            this.showGatewayTimeoutMessage = true
            return
          }
        }
        this.showHideAlert(true, 'O cancelamento da sua chamada falhou. Por favor, tente novamente ou entre em contato com o suporte.')
      } finally {
        this.actRunCLickCancelCall = false;
      }
    },

    // * FIREBASE
    async handleListenerRoom () {
      if (this.qsLinkAgenda) { // ! por link
        this.listenerRoomSpecificLinkAgora(this.qsLinkAgenda)
      }
      else if (this.qsAgendamentoPacienteId) { // ! por agp_id
        const resAgp = await this.ApiService.sGetSchedulePatientById.get({
          agpId: this.qsAgendamentoPacienteId
        })
        if (resAgp.status) {
          const objAgp = resAgp.data
          const funId = objAgp.agp_id_funcionarios
          const pacCpf = objAgp.usu_cpf
          const roomNameAddon = `${objAgp.day}${objAgp.month}${objAgp.year}_${objAgp.hour}${objAgp.minute}`
          const scheduleChannel = `AG-funId_${funId}-pacCpf_${pacCpf}_${roomNameAddon}`
          this.listenerRoomSpecificLinkAgora(scheduleChannel)
        }
      }
      else { // ! por Pronto Atendimento
        const patientId = this.pacId
        this.FirebaseService.listenerRoomLinkAgoraByPatientId(patientId, (payload) => {
          if (payload === null) {
            return
          }
          if (!payload.bloqueado) {
            this.controllerPayloadListenerRoom(payload)
            return
          }
          if (payload.bloqueado === 0) {
            this.controllerPayloadListenerRoom(payload)
            return
          }
        });
      }
    },

    async listenerRoomSpecificLinkAgora (channelName) {
      this.objListenerRoom = channelName
      this.FirebaseService.listenerRoomSpecificLinkAgora(channelName, async (payload) => {
        this.controllerPayloadListenerRoom(payload)
      });
    },

    async controllerPayloadListenerRoom (payload) {
      if (!payload) { // ! Sem payload. Sair da sala
        this.roomLinkAgoraPayloadNull()
        return
      }
      if (payload.changeType === 'modified') { // ! Sala modificada.
        this.roomLinkAgoraPayloadModified(payload)
        return
      }
      if (payload.changeType === 'added') { // ! Sala criada. Perguntar se pode entrar
        this.roomLinkAgoraPayloadAdded(payload)
        return
      }
      if (payload.changeType === 'removed') { // ! Sala destruida. Sair da sala
        this.roomLinkAgoraPayloadRemoved(payload)
        return
      }
      // ! Nenhuma opcao anterior
      console.error('[listenerRoomLinkAgora]', 'Nao entrou em nenhum IF')
    },

    async roomLinkAgoraPayloadNull () {
      this.objListenerRoom = null
      await this.actLeaveRoom()
      this.sendAsksEnterRoom = false
      this.enteringRoom = false
      this.cmdForward = false
    },

    async roomLinkAgoraPayloadModified (payload) {
      this.objListenerRoom = payload
      if (payload.autorizacaoEntrar === 'respostaNao') { // ! sem autorizacao para entrar. Resposta Nao
        const channel = payload.canal
        const key = 'autorizacaoEntrar'
        const value = null
        await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
        this.showHideAlertNoTime(true, `No momento, profissional ${payload.funNome} não está disponível para atendê-lo.`)
        setTimeout(() => {
          this.showHideAlertNoTime(false, '')
          this.actShowModalCancelCall()
        }, 5 * 1000)
        return
      }
      if (payload.autorizacaoEntrar === 'respostaSim') { // ! autorizacao para entrar na sala
        const channel = payload.canal
        const key = 'autorizacaoEntrar'
        const value = null
        await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
        this.enteringRoom = true
        this.objFun = {
          fun_id: payload.funId,
          fun_nome: payload.funNome,
        }
        this.labelCC = ``
        this.sublabelCC = ``
        await this.actLeaveRoom()
        this.actEnterRoom(payload)
        return
      }
      // ! perguntar se pode entrar na sala
      this.objListenerRoom = payload
      if (!this.sendAsksEnterRoom) {
        this.sendAsksEnterRoom = true
        const channel = payload.canal
        const key = 'autorizacaoEntrar'
        const value = 'pergunta'
        await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
      }
      this.labelCC = `Profissional ${payload.funNome} estará disponível para atendê-lo em breve.`
      this.sublabelCC = ``
      this.startTimeoutNotifyProfessionalDelay()
    },

    async roomLinkAgoraPayloadAdded (payload) {
      this.objListenerRoom = payload
      this.sendAsksEnterRoom = true
      const channel = payload.canal
      const key = 'autorizacaoEntrar'
      const value = 'pergunta'
      await this.FirebaseService.updateRoomSpecificKey(channel, key, value)
      this.labelCC = `Profissional ${payload.funNome} estará disponível para atendê-lo em breve.`
      this.sublabelCC = ``
      this.startTimeoutNotifyProfessionalDelay()
    },

    async roomLinkAgoraPayloadRemoved (payload) {
      this.objListenerRoom = null
      await this.actLeaveRoom()
      this.sendAsksEnterRoom = false
      this.enteringRoom = false
      this.cmdForward = false
      if (payload.autorizacaoEntrar) { // ! caso sala destruida tenha status de pergunta
        if (payload.autorizacaoEntrar === 'pergunta') {
          // this.startCallingCircle(); // comentado por conta do caso pleni/pleni#1488
        }
      }
    },

    async listenerPatientCommand () {
      const patientId = this.pacId
      this.FirebaseService.listenerByPatientId(patientId, (snap) => {
        // ! fila requisicao
        this.labelCC = ``;
        this.sublabelCC = ``;
        if (this.wasInRequestQueue) {
          this.labelCC = `Em breve você será atendido`;
        }
        if (snap.data.filaRequisicaoProntoAtendimento !== 0) {
          this.labelCC = `Você está na fila.`;
          this.sublabelCC = `Seu número é ${snap.data.filaRequisicaoProntoAtendimento}`;
          this.wasInRequestQueue = true
          // ! Firebase LOG
          const objLog = { ...this.patient, fila: `${this.labelCC}. ${this.sublabelCC}` }
          const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
          MovementLogs.saveLog(1714, timeAction, objLog)
        }
        // ! se nao for modificacao, faz nada
        if (snap.type !== 'modified') {
          return
        }
        this.listenerPatientCommandModified(snap)
      });
    },

    async listenerPatientCommandModified (payload) {
      const data = payload.data
      // ! comando - nenhum
      if (data.comando === "nenhum" || data.comando === null) {
      }
      // ! comando - recarregar pagina
      if (data.comando === "recarregarPagina") {
        if (this.cmdReloadPage) {
          return
        }
        window.location.reload();
        this.cmdReloadPage = true
      }
      // ! comando - finalizando atendimento
      if (data.comando === "finalizandoAtendimento") {
        this.profissionalEntered = false;
        this.enteringRoom = false
        this.sendAsksEnterRoom = false
        const isServiceFinished = true
        this.actShowModalCancelCall(isServiceFinished)
      }
      // ! comando - encaminhamento medico
      if (data.comando === "encaminhamentoMedico") {
        if (this.cmdForward) {
          return;
        }
        this.showHideAlert(true, 'Você foi encaminhado para um médico. Por favor, aguarde o seu atendimento.')
        this.enteringRoom = false
        this.sendAsksEnterRoom = false
        this.cmdForward = true;
        this.profissionalEntered = false;
        const patientId = this.pacId
        const key = 'comando'
        const value = null
        this.FirebaseService.updatePatientSpecificKey(patientId, key, value);
        this.startTimeoutNotifyProfessionalDelay()
        this.actLeaveRoom()
      }
      // ! comando - enviado para fila medica
      if (data.comando === "enviadoParaFilaMedica") {
        if (this.cmdSendToDoctorQueue) {
          return;
        }
        this.profissionalEntered = false;
        this.startTimeoutNotifyProfessionalDelay()
        this.cmdSendToDoctorQueue = true;
      }
      // ! comando - exibir mensagem de timeout
      if (data.comando === "showGatewayTimeoutMessage") {
        if (this.cmdShowGatewayTimeoutMessage) {
          return;
        }
        this.showGatewayTimeoutMessage = true
        this.cmdShowGatewayTimeoutMessage = false;
        const patientId = this.pacId
        const key = 'comando'
        const value = null
        this.FirebaseService.updatePatientSpecificKey(patientId, key, value);
      }
      // ! comando - pergunta / resposta
      if (data.comando === "perguntaArm" || data.comando === "perguntaMedico") {
        if (this.cmdAskArmOrDoctor) {
          return;
        }
        this.cmdAskArmOrDoctor = false;
        const patientId = this.pacId
        const key = 'comando'
        const value = 'resposta'
        this.FirebaseService.updatePatientSpecificKey(patientId, key, value);
      }
      // ! comando - cancelar ligacao
      if (data.comando === "cancelaLigacao") {
        if (this.cmdCancelCall) {
          return;
        }
        this.onClickCancelCall()
        this.cmdCancelCall = true
      }
    },

    // * AGORA SDK
    async actEnterRoom (payload) {
      this.showVideo = true
      await this.AgoraService.enterRoom(payload, this.patient)
      this.profissionalEntered = true
    },

    async actLeaveRoom () {
      await this.AgoraService.leaveRoom()
    },

    // * ALERTAS
    async showHideAlertNoTime (status, txt) {
      this.alertTxt = txt;
      this.showAlert = status;
    },

    async showHideAlert (status, txt, gotoNps = false) {
      this.alertTxt = txt;
      this.showAlert = status;
      setTimeout(async () => {
        this.showAlert = false;
        this.alertTxt = null;
        if (gotoNps) {
          // this.$router.push({ name: "pacienteHome" });
          await this.gotoNps();
        }
      }, 8 * 1000);
    },

    // * GOTO
    async gotoNps () {
      // * pega informacoes do ULTIMO atendimento
      const response = await this.servicoHttp.get({
        url: "api/pep/atendimento/obter-ultimo-atendimento",
        params: { pacId: this.pacId },
      });
      if (response.data.status) {
        this.attendanceData = { ...response.data.data };
      }

      if (this.attendanceData?.atd_id) {
        const params = {
          name: "pacienteNps",
          query: {
            video: this.attendanceData?.atd_id,
            from: this.qsSystemFrom
          },
        };
        return this.$router.push(params);
      } else {
        return this.$router.push({ name: "pacienteHome" });
      }
    },

    // * VALIDACOES
    async getPermissions () {
      try {
        // ! Firebase LOG
        const objLog = { ...this.patient };
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        MovementLogs.saveLog(1706, timeAction, objLog);

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        // ! Firebase LOG
        const objLog = { ...this.patient };
        const timeAction = moment().format("YYYY-MM-DD HH:mm:ss");
        MovementLogs.saveLog(1707, timeAction, objLog);

        this.$toast.error("A Webcam/Microfone não estão liberados para o uso", {
          duration: 3000,
        });
      }
    },

    // *ACTs Video
    onClickVideoOnOff () {
      this.AgoraService.videoOnOff((response) => {
        const objLog = { ...this.userLogin }
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        if (response.status) {
          this.videoOn = true
          MovementLogs.saveLog(1702, timeAction, objLog)
          } else {
          this.videoOn = false
          MovementLogs.saveLog(1703, timeAction, objLog)
        }
      })
    },

    onClickAudioOnOff () {
      this.AgoraService.audioOnOff((response) => {
        const objLog = { ...this.userLogin }
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        if (response.status) {
          this.audioOn = true
          MovementLogs.saveLog(1704, timeAction, objLog)
        } else {
          this.audioOn = false
          MovementLogs.saveLog(1705, timeAction, objLog)
        }
      })
    },

    onClickBlurOnOff () {
      this.AgoraService.blurOnOff((response) => {
        if (response.status) {
          this.blurOn = false
        } else {
          this.blurOn = true
        }
      })
    },

    // * MODAL DELAY
    startTimeoutNotifyProfessionalDelay () {
      if (this.profissionalEntered || this.showServiceDelayAlert) {
        return
      }
      this.showServiceDelayAlert = false
      const seg = 60
      const min = 5
      setInterval(() => {
        if (this.profissionalEntered || this.showServiceDelayAlert) {
          return
        }
        this.showServiceDelayAlert = true
      }, min * seg * 1000);
    },

    async onClickContinueDelayAlert () {
      this.showServiceDelayAlert = false
      this.startTimeoutNotifyProfessionalDelay()
    },
  },
};
</script>
